import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
// Importing constants and custom components
import {
    ALLERGIES,
    PRODUCT_DISPLAY_TYPE,
    PRODUCT_PAYMENT_TYPE,
    TEMPLATE_TYPE,
    VAT_PRODUCT_TYPE,
    VAT_TYPE
} from '../common/Constants';
import RadioButtonGroup from '../common/RadioButtonGroup';
import CheckBoxNormal from './CheckboxNormal';
import InputField from '../common/InputField';
import { Product } from "../Model/Product";
import { Restaurant } from "../Model/RestaurantChainAggregate";
import { assertTokenValidity } from "../common/ReztoToken";
import ImagePicker from "./ImagePicker";
import ReztoImage from "../common/Image/ReztoImage";
import TagsCheckbox from "./TagsCheckbox";
import ReztoSwitch from "../common/ReztoSwitch";
import ConfirmDialog from "../Modal/ConfirmDialog";
import { userReztoStore } from "../state/rootStore";
import PriceGroup, { Group, GroupVariant, Price } from "./PriceGroup";


// Default image URL for products
export const reztoDefaultImageURL = "https://rezto-menuitems.s3.eu-north-1.amazonaws.com/rezto_standard_green.png";

function EditProduct() {
    // Extracting productId and restaurantId from URL parameters
    let { productId } = useParams<{ productId: string }>();
    let { restaurantId } = useParams<{ restaurantId: string }>();

    // Initializing history for navigation
    const history = useHistory();
    // Accessing global state and actions
    const { reztoAuth, productStateSlice } = userReztoStore();
    const { restaurantChainAggregateState } = userReztoStore();

    // Finding the product to edit based on productId
    const product = useMemo(() => productStateSlice.products.find((p: Product) => p.id === productId), [productId, productStateSlice.products]);

    // State for selected image URL and copy dialog
    const [selectedImageUrl, setSelectedImageUrl] = useState("");
    const [copyDialogOpen, setCopyDialogOpen] = useState(false);

    // Setting default values for a new product or using existing product data
    const previousProduct = useMemo(() => product ? product : {
        'imageUrl': reztoDefaultImageURL,
        'allergies': [],
        'categories': [],
        'tags': [],
        'variants': [],
        'selectableIngredients': [],
        'templateType': TEMPLATE_TYPE.Ingen,
        'productPaymentType': PRODUCT_PAYMENT_TYPE.Maträtt,
        'productDisplayType': PRODUCT_DISPLAY_TYPE.Maträtt,
        'vatPercentage': VAT_TYPE['25 %'],
        'vatProductGroup': VAT_PRODUCT_TYPE.Mat
    }, [product]);

    // Extracting unique categories, tags, and selectable ingredients from all products
    const { allCategories, allTags, allSelectableIngredients } = useMemo(() => {
      const categories = Array.from(new Set<string>(productStateSlice.products.flatMap((p: Product) => p.categories)));
      const tags = Array.from(new Set<string>(productStateSlice.products.flatMap((p: Product) => p.tags)));
      const selectableIngredients = Array.from(new Set<string>(productStateSlice.products.flatMap((p: Product) => p.selectableIngredients)));
      return { allCategories: categories, allTags: tags, allSelectableIngredients: selectableIngredients };
    }, [productStateSlice.products]);

    // State for prices
    const [prices, setPrices] = useState<Group[]>(
      product?.priceGroups?.map((group: any) => ({
        ...group,
        variants: group.variants.map((variant: any) => ({
          ...variant,
          prices: variant.prices.map((price: any) => ({
            ...price,
            amount: { ...price.amount, amount: price.amount.amount / 100 }
          }))
        }))
      })) || []
    );

     console.log("product.pricegroups = "+ product?.priceGroups);

    // Initializing react-hook-form
    const { register, reset, handleSubmit, control } = useForm({
        defaultValues: useMemo(() => {
            return {
                costPriceForm: product?.costPrice ? product?.costPrice?.amount / 100 : 0,
                ...previousProduct
            };
        }, [product, previousProduct])
    });

    // Effect to update form when product changes
    useEffect(() => {
        if (product) {
            setSelectedImageUrl(product.imageUrl);
            reset({
                ...previousProduct,
                eatHere: product?.eatHere ? product.eatHere / 100 : 0,
                takeAway: product?.takeAway ? product.takeAway / 100 : 0,
                costPriceForm: product?.costPrice ? product.costPrice.amount / 100 : 0
            });
        }
    }, [product, reset, previousProduct]);

    // Function to handle form submission
    const onSub = (data: any) => {

        // Transform prices data to match the expected API format
          const transformedPrices = prices.map((group: Group) => ({
                id: group.id,
                name: group.name,
                variants: group.variants.map((variant: GroupVariant) => ({
                    id: variant.id,
                    name: variant.name,
                    imageUrl: variant.imageUrl,
                    prices: variant.prices.map((price: Price) => ({
                        ...price,
                        amount: {
                            ...price.amount,
                            amount: Math.round(price.amount.amount * 100)
                        }
                    })),
                }))
            }));

        data = {
            name: data.name,
            desc: data.desc,
            restaurantId: restaurantId,
            restaurantChainId: restaurantChainAggregateState.restaurants.find((restaurant: Restaurant) => restaurant.id === restaurantId)!.restaurantChainId,
            templateType: data.templateType,
            productPaymentType: data.productPaymentType,
            productDisplayType: data.productDisplayType,
            categories: data.categories === false ? [] :
                typeof data.categories === 'string' ? [data.categories] : data.categories,
            tags: data.tags === false ? [] :
                typeof data.tags === 'string' ? [data.tags] : data.tags,
            priceGroups: transformedPrices,
            costPrice: {
                'amount': Math.round(+data.costPriceForm * 100)
            },
            vatPercentage: data.vatPercentage,
            vatProductGroup: data.vatProductGroup,
            imageUrl: selectedImageUrl,
            allergies: data.allergies,
            ingredients: null,
            selectableIngredients: data.selectableIngredients === false ? [] :
                typeof data.selectableIngredients === 'string' ? [data.selectableIngredients] : data.selectableIngredients
        };

        // Print the data to the console
        console.log("Data collected from the form:", data);

        // Update or create product based on whether productId exists
        if (productId) {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    productStateSlice.updateProduct(data, reztoAuth, history, restaurantId, productId);
                });
        } else {
            assertTokenValidity(reztoAuth)
                .then((_) => {
                    productStateSlice.createProduct(data, reztoAuth, history, restaurantId);
                });
        }
    };

    // Function to handle product publishing
    function publish(checked: boolean) {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                productStateSlice.publishProduct(product.id, reztoAuth, !checked);
            });
    }

    // Function to handle product copying
    function copy() {
        assertTokenValidity(reztoAuth)
            .then((_) => {
                productStateSlice.copyProduct({
                    productId: product.id,
                    newName: product.name + " - COPY"
                }, reztoAuth, history, restaurantId);
            });
    }

    // Function to handle price changes
   const handlePricesChange = (newPrices: Group[]) => {
       setPrices(newPrices);
   };

    // Return null if productId exists but product is not found
    if (productId && !product) {
        return null;
    }

    // Render the component
    return (
        <div className="edit-product-container">
            {/* Header section */}
            <div className="flex justify-between items-center">
                <h1 className="text-4xl mt-5 mb-2 text-mainFont">{product ? 'Redigera produkt' : 'Ny produkt'}</h1>

                {product && (
                    <>
                        <ReztoSwitch defaultChecked={product.published} callback={publish} />
                        <ConfirmDialog callBack={copy} title="Kopiera" open={copyDialogOpen} setOpen={setCopyDialogOpen} />
                        <button
                            onClick={() => setCopyDialogOpen(true)}
                            className="dropdown-button h-10">
                            Kopiera
                        </button>
                    </>
                )}
            </div>
            <hr />

            {/* Form section */}
            <form autoComplete="off" className="flex flex-col mt-5" onSubmit={handleSubmit(onSub)}>
                <div className="flex flex-row justify-between lg:flex-row xs:flex-col">
                    <div className="flex-one mr-10">
                        <InputField name="name" title="Produktnamn" control={control} />
                        <PriceGroup onPricesChange={handlePricesChange} initialGroups={prices} />
                        <InputField name="costPriceForm" title="Inköpespris" control={control} />
                    </div>

                    <div className="flex-one">
                        {selectedImageUrl !== "" &&
                            <ReztoImage imageUrl={selectedImageUrl} height={300} width={300} />
                        }
                        <div className="mt-5">
                            <ImagePicker selectedImageUrl={selectedImageUrl} setSelectedImageUrl={setSelectedImageUrl} />
                        </div>
                    </div>
                </div>

                {/* Product description */}
                <label className="input-label-normal">Produktens beskrivning</label>
                <textarea rows={3} className="input-normal bg-secondary"
                    placeholder={'Beskrivning'} {...register("desc")} />

                {/* Various checkbox groups */}
                <TagsCheckbox
                    title={'Kategorier'}
                    register={register}
                    values={allCategories.filter((a: any) => a)}
                    formControlName={'categories'}
                />

                <TagsCheckbox
                    title={'Valbara ingredienser'}
                    register={register}
                    values={allSelectableIngredients.filter((a: any) => a)}
                    formControlName={'selectableIngredients'}
                />

                <TagsCheckbox
                    title={'Taggar'}
                    register={register}
                    values={allTags.filter((a: any) => a)}
                    formControlName={'tags'}
                />

                <CheckBoxNormal
                    title={'Allergier'}
                    register={register}
                    values={ALLERGIES}
                    formControlName={'allergies'}
                />

                {/* Various radio button groups */}
                <RadioButtonGroup title={'Template'} values={TEMPLATE_TYPE} register={register}
                    formControlName={'templateType'} />
                <RadioButtonGroup title={'Maträtt'} values={PRODUCT_PAYMENT_TYPE} register={register}
                    formControlName={'productPaymentType'} />
                <RadioButtonGroup title={'Presentationstyp'} values={PRODUCT_DISPLAY_TYPE} register={register}
                    formControlName={'productDisplayType'} />
                <RadioButtonGroup title={'Moms'} values={VAT_TYPE} register={register}
                    formControlName={'vatPercentage'} />
                <RadioButtonGroup title={'Momssortering'} values={VAT_PRODUCT_TYPE} register={register}
                    formControlName={'vatProductGroup'} />

                {/* Submit button */}
                <div className="flex justify-around">
                    <button type="submit" className="dropdown-button mb-12">Spara</button>
                </div>
            </form>
        </div>
    )
}

export default EditProduct;
