import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {Amount} from '../Model/Product'
import { useForm, Controller } from 'react-hook-form';

interface PriceInputProps {
  title: string;
  isEditable: boolean;
  original: Amount;
  takeaway: Amount;
  onPriceChange: (title: string, original: Amount, takeaway: Amount) => void;
}

export interface GroupVariant {
  id?: string;
  name: string;
  prices: Price[];
  imageUrl: string;
}

export interface Group {
  id?: string;
  name: string;
  variants: GroupVariant[];
}

export interface Price {
  priceCategory: string;
  amount: Amount;
}

interface PriceGroupProps {
  onPricesChange: (prices: Group[]) => void;
  initialGroups?: Group[];
}

const PriceInput: React.FC<PriceInputProps> = ({ title, isEditable, original, takeaway, onPriceChange }) => {
  const { control } = useForm({
    defaultValues: useMemo(() => ({
      title,
      original: original.amount,
      takeaway: takeaway.amount,
    }), [title, original.amount, takeaway.amount])
  });

  const handleChange = useCallback((field: string, value: string | number) => {
    const originalValue = field === 'original' ? { amount: Number(value) } : original;
    const takeawayValue = field === 'takeaway' ? { amount: Number(value) } : takeaway;
    const titleValue = field === 'title' ? String(value) : title;
    
    onPriceChange(titleValue, originalValue, takeawayValue);
  }, [onPriceChange, original, takeaway, title]);

  return (
    <div className="flex flex-col mb-4 p-2 border rounded">
      <Controller
        name="title"
        control={control}
        render={({ field }) => (
          <input
            {...field}
            className="input-normal bg-secondary"
            placeholder="Titel"
            disabled={!isEditable}
            onChange={(e) => {
              field.onChange(e);
              handleChange('title', e.target.value);
            }}
          />
        )}
      />
      <Controller
        name="original"
        control={control}
        render={({ field }) => (
          <input
            {...field}
            type="number"
            step="0.01"
            className="input-normal bg-secondary"
            placeholder="Originalpris"
            onChange={(e) => {
              field.onChange(e);
              handleChange('original', e.target.value);
            }}
          />
        )}
      />
      <Controller
        name="takeaway"
        control={control}
        render={({ field }) => (
          <input
            {...field}
            type="number"
            step="0.01"
            className="input-normal bg-secondary"
            placeholder="Take away"
            onChange={(e) => {
              field.onChange(e);
              handleChange('takeaway', e.target.value);
            }}
          />
        )}
      />
    </div>
  );
};

const PriceGroup: React.FC<PriceGroupProps> = ({ onPricesChange, initialGroups = [] }) => {
  const [groups, setGroups] = useState<Group[]>(initialGroups || []);

  const updatePrices = useCallback(() => {
    onPricesChange(groups);
  }, [groups, onPricesChange]);

  const addGroup = useCallback(() => {
    setGroups((prevGroups) => {
      const newGroup: Group = {
        name: `Prisgrupp ${prevGroups.length + 1}`,
        variants: [{
          name: '',
          imageUrl: 'www.exampleimage.com',
          prices: [
            { priceCategory: 'ORIGINAL', amount: { amount: 0 } },
            { priceCategory: 'TAKEAWAY', amount: { amount: 0 } }
          ],
        }]
      };
      return [...prevGroups, newGroup];
    });
  }, []);

  const addVariant = useCallback((groupIndex: number) => {
    setGroups((prevGroups) => {
      return prevGroups.map((group, index) => {
        if (index === groupIndex) {
          const newVariant: GroupVariant = {
            name: ``,
            imageUrl: 'www.exampleimage.com',
            prices: [
              { priceCategory: 'ORIGINAL', amount: { amount: 0 } },
              { priceCategory: 'TAKEAWAY', amount: { amount: 0 } }
            ],
          };
          return { ...group, variants: [...group.variants, newVariant] };
        }
        return group;
      });
    });
  }, []);

  const handleGroupNameChange = useCallback((groupIndex: number, newName: string) => {
    setGroups((prevGroups) => 
      prevGroups.map((group, index) =>
        index === groupIndex ? { ...group, name: newName } : group
      )
    );
  }, []);

  const handlePriceChange = useCallback((groupIndex: number, variantIndex: number, title: string, original: Amount, takeaway: Amount) => {
    setGroups((prevGroups) => 
      prevGroups.map((group, gIndex) => 
        gIndex === groupIndex
          ? {
              ...group,
              variants: group.variants.map((variant, vIndex) =>
                vIndex === variantIndex
                  ? {
                      ...variant,
                      name: title,
                      prices: [
                        { priceCategory: 'ORIGINAL', amount: original },
                        { priceCategory: 'TAKEAWAY', amount: takeaway }
                      ]
                    }
                  : variant
              )
            }
          : group
      )
    );
  }, []);

  const removeGroup = useCallback((groupIndex: number) => {
    setGroups((prevGroups) => prevGroups.filter((_, index) => index !== groupIndex));
  }, []);

  const removeVariant = useCallback((groupIndex: number, variantIndex: number) => {
    setGroups((prevGroups) => 
      prevGroups.map((group, gIndex) => 
        gIndex === groupIndex
          ? {
              ...group,
              variants: group.variants.filter((_, vIndex) => vIndex !== variantIndex)
            }
          : group
      )
    );
  }, []);

  useEffect(() => {
    updatePrices();
  }, [groups, updatePrices]);

  return (
    <div>
      {groups.map((group, groupIndex) => (
        <div key={group.id || groupIndex} className="mb-8 p-4 border rounded">
          <div className="flex justify-between items-center mb-2">
            <input
              className="input-normal bg-secondary"
              placeholder="Prisgrupp titel"
              value={group.name}
              onChange={(e) => handleGroupNameChange(groupIndex, e.target.value)}
            />
            {groups.length > 1 && (
              <button type="button" onClick={() => removeGroup(groupIndex)} className="dropdown-button">
                Ta bort prisgrupp
              </button>
            )}
          </div>
          {group.variants.map((variant, variantIndex) => (
            <div key={variant.id || variantIndex} className="relative">
              <PriceInput
                title={variant.name}
                isEditable={true}
                original={variant.prices.find(p => p.priceCategory === 'ORIGINAL')?.amount || { amount: 0 }}
                takeaway={variant.prices.find(p => p.priceCategory === 'TAKEAWAY')?.amount || { amount: 0 }}
                onPriceChange={(title, original, takeaway) => handlePriceChange(groupIndex, variantIndex, title, original, takeaway)}
              />
              {group.variants.length > 1 && (
                <button
                  type="button"
                  onClick={() => removeVariant(groupIndex, variantIndex)}
                  className="absolute top-0 right-0 dropdown-button"
                >
                  Ta bort
                </button>
              )}
            </div>
          ))}
          <button type="button" onClick={() => addVariant(groupIndex)} className="dropdown-button mt-2">
            Lägg till variant
          </button>
        </div>
      ))}
      <button type="button" onClick={addGroup} className="dropdown-button mb-4">
        Ny prisgrupp
      </button>
    </div>
  );
};

export default PriceGroup;
