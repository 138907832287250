import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ImportMenuFormProps {
    setImportDialogOpen: (open: boolean) => void;
}

const ImportMenuForm: React.FC<ImportMenuFormProps> = ({ setImportDialogOpen }) => {
    const [importText, setImportText] = useState('');
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const { t } = useTranslation();

    const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedImage(file);
            displayImagePreview(file);
        }
    };

    const uploadImage = async (file: File) => {
        // Implement the logic to send the image to the backend
    };

    const displayImagePreview = (file: File) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result as string);
        };
        reader.readAsDataURL(file);
    };

    const handleImport = () => {
        // Here you would handle the import logic
        console.log('Importing menu:', importText);
        if (selectedImage) {
            uploadImage(selectedImage);
        }
        // After import logic, close the dialog
        setImportDialogOpen(false);
    };

    return (
        <div className="flex flex-col space-y-4">
            <textarea
                className="w-full h-32 p-2 border border-gray-300 rounded"
                value={importText}
                onChange={(e) => setImportText(e.target.value)}
                placeholder={t('IMPORT_MENU_PLACEHOLDER')}
            />
            <input type="file" onChange={handleImageSelect} />
            {imagePreview && <img src={imagePreview} alt="Preview" className="max-w-full h-auto" />}
            <button
                className="dropdown-button h-10"
                onClick={handleImport}
            >
                {t('IMPORT')}
            </button>
            <button className="dropdown-button h-10">
                Bifoga menybild
            </button>
        </div>
    );
};

export default ImportMenuForm;
