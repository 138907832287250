import React, { useEffect } from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import './App.css';
import Dashboard from './dashboard/dashboard';
import Navbar from './navbar/Navbar';
import ReztoDrawer from "./drawer/ReztoDrawer";
import ReztoSnackbar from "./Snackbar/ReztoSnackbar";
import ScrollToTop from "./common/ScrollToTop";
import RestaurantChainPage from "./RestaurantChain/RestaurantChainPage";
import WelcomeMessage from './components/WelcomeMessage';
import { RestaurantChain } from "./Model/RestaurantChainAggregate";
import FlowSection from "./Flow/FlowSection";
import FlowList from "./Flow/FlowList";
import MenuList from './menu/MenuList';
import MenuModelPage from './menu/MenuModelPage';
import EditProduct from './product/EditProduct';
import ProductList from './product/ProductList/ProductList';
import KioskList from "./Kiosk/KioskList";
import KioskPage from "./Kiosk/KioskPage";
import ReceiptList from "./Receipts/ReceiptList";
import ReportList from "./Reports/ReportList";
import StatisticsPage from "./Statistics/StatisticsPage";
import ImageBankPage from "./ImageBank/ImageBankPage";
import CashierPage from "./cashiers/CashierPage";
import { userReztoStore } from "./state/rootStore";
import UserPage from "./Users/UserPage";
import KioskConfigurationPage from "./Kiosk/KioskConfigurationPage";

const routes = [
    { path: "/restaurant-chains", component: RestaurantChainPage },
    { path: "/dashboard", component: Dashboard },
    { path: "/products", component: ProductList },
    { path: "/products/:productId/edit", component: EditProduct },
    { path: "/products/create", component: EditProduct },
    { path: "/menu-models", component: MenuList },
    { path: "/flows", component: FlowList },
    { path: "/flows/:flowId", component: FlowSection },
    { path: "/menu-models/:menuModelId", component: MenuModelPage },
    { path: "/kiosks", component: KioskList },
    { path: "/kiosks/:kioskId", component: KioskPage },
    { path: "/kiosks/:kioskId/configuration", component: KioskConfigurationPage },
    { path: "/receipts", component: ReceiptList },
    { path: "/reports", component: ReportList },
    { path: "/statistics", component: StatisticsPage },
    { path: "/images", component: ImageBankPage },
    { path: "/cashiers", component: CashierPage },
    { path: "/users", component: UserPage },
];

function Home() {
    const { reztoAuth, restaurantChainAggregateState, setSelectedRestaurant } = userReztoStore();
    const { path } = useRouteMatch();
    const { restaurantId } = useParams<{ restaurantId: string }>();
    const { restaurantChainId } = useParams<{ restaurantChainId: string }>();

    useEffect(() => {
        if (restaurantId !== undefined && restaurantChainAggregateState.restaurants.length > 0) {
            let resChainId = restaurantChainAggregateState.restaurants.find((r: any) => r.id === restaurantId)?.restaurantChainId;
            if (resChainId) {
                setSelectedRestaurant(restaurantId, resChainId);
            }
        }
    }, [restaurantId, restaurantChainAggregateState, setSelectedRestaurant]);

    return (
        <div>
            <Navbar />
            {restaurantChainId && (
                <ScrollToTop>
                    <div className="flex-row flex justify-center z-0 pt-16 w-full">
                        <RestaurantChainPage />
                    </div>
                </ScrollToTop>
            )}
            {restaurantId && (
                <div className="flex-row flex wrapper-container lg:pr-0 sm:pr-10 z-0 pt-14 w-full">
                    <ReztoDrawer />
                    <div className="content-header">
                        <ReztoSnackbar />
                        <ScrollToTop>
                            <Switch>
                                {routes.map((route) => (
                                    <Route 
                                        key={route.path} 
                                        exact 
                                        path={`${path}${route.path}`} 
                                        component={route.component} 
                                    />
                                ))}
                            </Switch>
                        </ScrollToTop>
                    </div>
                </div>
            )}
            {!restaurantId && !restaurantChainId && (
                <WelcomeMessage 
                    email={reztoAuth?.email} 
                    restaurantChains={restaurantChainAggregateState.restaurantChains} 
                />
            )}
        </div>
    );
}

export default Home;
