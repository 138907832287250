import Paper from '@mui/material/Paper'
import React, { useState, useEffect } from 'react'
import { assertTokenValidity } from "../common/ReztoToken";
import { userReztoStore } from "../state/rootStore";

export default function PriceGroupCard({ form, productId, priceGroupId }: { form: any, productId: string | undefined, priceGroupId: string | undefined }) {
    const [selection, setSelection] = useState('');
    const [priceGroups, setPriceGroups] = useState<any[]>([]);
    const [selectedPriceGroup, setSelectedPriceGroup] = useState<any>(null);
    const { reztoAuth, productStateSlice } = userReztoStore();

    useEffect(() => {
        if (productId) {
            fetchProduct(productId);
        } else {
            setPriceGroups([]);
            setSelectedPriceGroup(null);
            form.setFieldValue('priceGroupId', '');
        }
    }, [productId]);

    const fetchProduct = async (productId: string) => {
        try {
            await assertTokenValidity(reztoAuth);
            const product = await productStateSlice.getProduct(productId, reztoAuth);
            console.log("array med alla pricegroups för produkten är", product.priceGroups)
            setPriceGroups(product.priceGroups || []);
        } catch (error) {
            console.error("Failed to fetch product:", error);
        }
    };

    useEffect(() => {
        const selectedPriceGroupId = form.values.priceGroupId;
        if (selectedPriceGroupId) {
            console.log("pricegroupId är följande", priceGroupId);
            const selected = priceGroups.find(pg => pg.id === selectedPriceGroupId);
            console.log("selected är pricegroup som är ", selected);
            setSelectedPriceGroup(selected);
        }
    }, [form.values.priceGroupId, priceGroups]);

    return (
        <>
            <Paper onClick={() => { setSelection('pricegroup') }}
                   style={{
                       width: '100%', height: '100%', display: 'flex',
                       cursor: 'pointer',
                       alignItems: 'center',
                       justifyContent: 'space-around', flexDirection: 'column'
                   }}>
                {selectedPriceGroup ? (
                    <h2 style={{ marginLeft: 'auto', marginRight: 'auto' }}>{selectedPriceGroup.name}</h2>
                ) : (
                    <h2 style={{ marginLeft: 'auto', marginRight: 'auto' }}>Välj prisgrupp*</h2>
                )}
            </Paper>
            {selection === 'pricegroup' &&
                <div>
                    <div className="absolute z-50 overflow-y-scroll flex flex-col gap-2 p-5
                  bg-secondary top-0 left-0 w-full min-h-full max-h-96">
                        {priceGroups.length > 0 ? (
                            priceGroups.map((priceGroup) => (
                                <h1 key={priceGroup.id} className="dropdown-button" onClick={() => {
                                    form.setFieldValue('priceGroupId', priceGroup.id);
                                    setSelectedPriceGroup(priceGroup);
                                    setSelection('');
                                }}>
                                    {priceGroup.name}
                                </h1>
                            ))
                        ) : (
                            <h1 className="dropdown-button">No price groups available</h1>
                        )}
                    </div>
                    <button className="dropdown-button absolute z-50 bottom-1 left-1 " onClick={() => {setSelection('')}}>Tillbaka</button>
                </div>
            }
            {form.errors.priceGroupId && (
                <div style={{ color: 'red', marginTop: '5px' }}>{form.errors.priceGroupId}</div>
            )}
        </>
    )
}
