import React, { useState } from 'react';
import { MenuProduct } from "../Model/Menu";
import { Product } from "../Model/Product";
import FormDialog from "../Modal/FormDialog";
import MenuProductForm from "./forms/MenuProductForm";
import ReztoImage from "../common/Image/ReztoImage";
import {userReztoStore} from "../state/rootStore";
import {MenuModelState} from "../state/MenuSlice";

//Klassen representerar en produkt i menymodellen. Mer specifikt en en produkt i en menykategori.

export default function MenuProductCard({
    menuProduct,
    menuModelId, menuId, menuCategoryId
}:
    {
        menuProduct: MenuProduct,
        menuModelId: string,
        menuId: string,
        menuCategoryId: string
    }) {

  
    const {productStateSlice, menuModelStateSlice} = userReztoStore();
    const menuModelState: MenuModelState = menuModelStateSlice;
    const products: Product[] = productStateSlice.products;
    const product = products.find(product => product.id === menuProduct.productId);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    const disabled = (
        !menuProduct.published ||
        !menuModelState.menuModels.find(x => x.id === menuModelId)!.menus.find(x => x.id === menuId)!.menuCategories.find(x => x.id === menuCategoryId)!.published ||
        !menuModelState.menuModels.find(x => x.id === menuModelId)!.menus.find(x => x.id === menuId)!.published ||
        !menuModelState.menuModels.find(x => x.id === menuModelId)!.published);



    return (
        <div className="max-w-sm bg-gray-50 rounded-2xl h-88 shadow-lg w-64
        text-2xl font-bold transform duration-100 hover:scale-105 m-3 pt-3">
            <div onClick={() => {setCreateDialogOpen(true)}} className="flex flex-col justify-between cursor-pointer">
                <div className="flex flex-row justify-center">
                    <ReztoImage
                        disabled={disabled}
                        height='170px' width='170px'
                        imageUrl={product?.imageUrl} />
                </div>

                <div className="flex flex-row p-3 justify-center">
                    <div className={"font-bold text-gray-700 text-xl overflow-ellipsis overflow-hidden whitespace-nowrap " + (menuProduct.published ? "" : "line-through")}>
                        {product?.name}</div>
                </div>
            </div>

            <div className="flex flex-row justify-between pl-3 pr-3 pb-3">
                {createDialogOpen &&
                    <FormDialog title={"Redigera menyprodukt"}
                        open={createDialogOpen} setOpen={setCreateDialogOpen}
                    >
                        <MenuProductForm setCreateDialogOpen={setCreateDialogOpen}
                            menuModelId={menuModelId}
                            menuId={menuId}
                            menuCategoryId={menuCategoryId}
                            menuProduct={menuProduct}
                        />
                    </FormDialog>
                }
            </div>
        </div>
    )
}
