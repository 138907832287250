import React, { useState, useCallback } from "react";
import './ProfileNavbar.css'
import OutsideClick from "../common/OutsideClick";
import { userReztoStore } from "../state/rootStore";
import { auth } from "../firebase";
import { useHistory } from "react-router-dom";
import { isAdmin } from "../common/Auth";

export default function ProfileNavbar() {
    const [show, setShow] = useState(false);
    const { reztoAuth } = userReztoStore();
    const history = useHistory();

    const logOut = useCallback(async () => {
        try {
            await auth.signOut();
            reztoAuth?.logout();
            history.push("/signin");
        } catch (e) {
            console.log(e);
        }
    }, [reztoAuth, history]);

    const firstLetter = reztoAuth?.email?.charAt(0).toUpperCase() || '?';

    const menuItems = [
        { label: "LOGGA UT", onClick: logOut },
        ...(isAdmin(reztoAuth) ? [{ label: "ANVÄNDARE", onClick: () => history.push("/users") }] : []),
        { label: "HEM", onClick: () => history.push("/home") }
    ];

    if (!reztoAuth) {
        return null; // or return a loading indicator
    }

    return (
        <OutsideClick clicked={() => setShow(false)}>
            <div className="sec-center">
                <button 
                    onClick={() => setShow(!show)} 
                    className="cursor-pointer select-none flex flex-col justify-center bg-primary dropdown w-12 h-12 rounded-full"
                    aria-label="Profile menu"
                    aria-expanded={show}
                >
                    <span className="text-2xl">{firstLetter}</span>
                </button>

                <div className={`section-dropdown ${show ? "dropdown-selected" : ""}`} role="menu">
                    {menuItems.map((item, index) => (
                        <button 
                            key={index} 
                            onClick={item.onClick} 
                            className="profile-drop-down-item-container"
                            role="menuitem"
                        >
                            {item.label}
                            <i className="profile-drop-down-menu" aria-hidden="true" />
                        </button>
                    ))}
                </div>
            </div>
        </OutsideClick>
    );
}


