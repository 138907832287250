import React from 'react';
import { Link } from 'react-router-dom';
import { RestaurantChain } from '../Model/RestaurantChainAggregate';

interface WelcomeMessageProps {
    email?: string;
    restaurantChains?: RestaurantChain[];
}

const WelcomeMessage: React.FC<WelcomeMessageProps> = ({ email, restaurantChains }) => {
    return (
        <div className="w-full h-screen flex flex-col justify-center items-center">
            <div className="shadow-2xl flex flex-col p-20 m-10">
                <div className="flex flex-row justify-center border-b-2 ml-10 mr-10">
                    <h1 className="text-mainFont sm:text-2xl xl:text-4xl lg:text-2xl p-5">
                        Välkommen, {email || 'Användare'}!
                    </h1>
                </div>
                <div className="flex flex-col justify-center h-full mt-10">
                    <div className="flex flex-row gap-3 flex-wrap justify-center">
                        {restaurantChains?.length ? (
                            restaurantChains.map((r: RestaurantChain) => (
                                <Link key={r.id} to={`/restaurant-chains/${r.id}`}>
                                    <div className="dropdown-button">{r.name}</div>
                                </Link>
                            ))
                        ) : (
                            <p>No restaurant chains available.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WelcomeMessage;
